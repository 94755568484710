<template>
  <div class="flex bg-white w-full border-b border-gray-200 p-4 justify-between items-center px-4 md:px-24">
    <img class="w-40" src="../assets/logo.svg" alt="simple cash logo">
    <svg class="md:hidden" v-if="!navIsOpen" @click="toggle" width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="3" fill="#002E54"/>
      <rect y="7" width="25" height="3" fill="#002E54"/>
      <rect y="14" width="25" height="3" fill="#002E54"/>
    </svg>

    <svg class="md:hidden" @click="toggle" v-else width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.12134" y="0.00012207" width="25.4584" height="3" transform="rotate(45 2.12134 0.00012207)" fill="#002E54"/>
      <rect y="18" width="25.4558" height="3" transform="rotate(-45 0 18)" fill="#002E54"/>
    </svg>



    <transition  name="fade">
        <div v-if="navIsOpen" class="md:hidden shadow-lg transition duration-500 ease-in-out transform w-screen bg-white h-56 absolute left-0 z-30 " style="top: 5rem">
          <ul class="">
            <li class="mx-6 my-2 hover:underline cursor-pointer">About Us</li>
            <li class="mx-6 my-2 hover:underline cursor-pointer">Contact Us</li>
            <li class="mx-6 my-2 hover:underline cursor-pointer"><a href="#faq">FAQ</a></li>
            <li class="mx-6 my-2 hover:underline cursor-pointer"><button class="border border-yellow-500 rounded-full px-6 py-1.5 hover:bg-yellow-500 hover:text-white">Agent Login</button></li>

          </ul>
          
        </div>
      </transition>

    <ul class="md:flex items-center hidden">
      <li class="mx-6 hover:underline cursor-pointer">About Us</li>
      <li class="mx-6 hover:underline cursor-pointer">Contact Us</li>
      <li class="mx-6 hover:underline cursor-pointer"><a href="#faq">FAQ</a></li>
      <li class="mx-6 hover:underline cursor-pointer"><a href="https://wa.me/+2349036189485?text=I'm%20interested%20in%20SimpleCash%20POS"><button class="border border-yellow-500 rounded-full px-6 py-1.5 hover:bg-yellow-500 hover:text-white">Chat with us</button></a> </li>

    </ul>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        navIsOpen: false
      }
    },
    methods: {
      toggle() {
        this.navIsOpen = !this.navIsOpen
      }
    }
  }
</script>

<style scoped>
.fade-enter, .fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>