<template>
  <div>
    <div class="flex flex-wrap justify-center md:justify-between items-center">
      <div class="flex flex-wrap text-left w-full md:w-6/12 justify-around py-24 ">
        <ul>
          <li class="cursor-pointer hover:underline"><h3 class="font-semibold text-2xl mb-6">Company</h3></li>
          <li class="cursor-pointer hover:underline">About Us</li>
          <li class="cursor-pointer hover:underline"><a href="/privacy">Privacy</a></li>
          <li class="cursor-pointer hover:underline"> <a href="/terms">Terms</a> </li>
        </ul>

         <ul>
          <li class="cursor-pointer hover:underline"><h3 class="font-semibold text-2xl mb-6">Resources</h3></li>
          <li class="cursor-pointer hover:underline">Customer Stories</li>
          <li class="cursor-pointer hover:underline">Media</li>
          <li class="cursor-pointer hover:underline">FAQ</li>
          <li class="cursor-pointer hover:underline">Blog</li>
        </ul>
      </div>

       <ul class="text-left w-full mx-8 md:w-4/12 mb-16 md:mb-0">
          <li class="cursor-pointer hover:underline hover:underline"><h3 class="font-semibold text-2xl mb-6">Contact</h3></li>
          <li class="cursor-pointer hover:underline my-2">+234 808 935 0963</li>
          <li class=" cursor-pointer hover:underline my-2">connect@yoursimplify.ng</li>
          <li class="cursor-pointer hover:underline flex my-2">
            <span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2969 12C14.2969 13.2686 13.2686 14.2969 12 14.2969C10.7314 14.2969 9.70312 13.2686 9.70312 12C9.70312 10.7314 10.7314 9.70312 12 9.70312C13.2686 9.70312 14.2969 10.7314 14.2969 12Z" fill="#FBBE00"/>
                <path d="M17.3716 7.93622C17.2612 7.63702 17.085 7.36621 16.8561 7.14392C16.6338 6.91504 16.3632 6.73889 16.0638 6.62848C15.821 6.53418 15.4563 6.42194 14.7845 6.39136C14.0577 6.35822 13.8398 6.35107 12 6.35107C10.16 6.35107 9.94208 6.35803 9.21551 6.39117C8.5437 6.42194 8.17877 6.53418 7.93616 6.62848C7.63678 6.73889 7.36597 6.91504 7.14386 7.14392C6.91498 7.36621 6.73883 7.63684 6.62823 7.93622C6.53394 8.17902 6.42169 8.54395 6.39111 9.21576C6.35797 9.94232 6.35083 10.1602 6.35083 12.0002C6.35083 13.8401 6.35797 14.058 6.39111 14.7847C6.42169 15.4565 6.53394 15.8213 6.62823 16.0641C6.73883 16.3635 6.91479 16.6341 7.14368 16.8564C7.36597 17.0853 7.6366 17.2614 7.93597 17.3718C8.17877 17.4663 8.5437 17.5786 9.21551 17.6091C9.94208 17.6423 10.1598 17.6492 11.9998 17.6492C13.84 17.6492 14.0579 17.6423 14.7843 17.6091C15.4561 17.5786 15.821 17.4663 16.0638 17.3718C16.6648 17.14 17.1398 16.665 17.3716 16.0641C17.4659 15.8213 17.5781 15.4565 17.6089 14.7847C17.642 14.058 17.649 13.8401 17.649 12.0002C17.649 10.1602 17.642 9.94232 17.6089 9.21576C17.5783 8.54395 17.4661 8.17902 17.3716 7.93622ZM12 15.5384C10.0457 15.5384 8.46149 13.9543 8.46149 12.0001C8.46149 10.0458 10.0457 8.46173 12 8.46173C13.9541 8.46173 15.5383 10.0458 15.5383 12.0001C15.5383 13.9543 13.9541 15.5384 12 15.5384ZM15.6782 9.14874C15.2216 9.14874 14.8513 8.7785 14.8513 8.32184C14.8513 7.86517 15.2216 7.49493 15.6782 7.49493C16.1349 7.49493 16.5051 7.86517 16.5051 8.32184C16.5049 8.7785 16.1349 9.14874 15.6782 9.14874Z" fill="#FBBE00"/>
                <path d="M12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C18.6264 24 24 18.6264 24 12C24 5.3736 18.6264 0 12 0ZM18.8491 14.8409C18.8157 15.5744 18.6991 16.0752 18.5288 16.5135C18.1708 17.4391 17.4391 18.1708 16.5135 18.5288C16.0754 18.6991 15.5744 18.8156 14.8411 18.8491C14.1063 18.8826 13.8715 18.8906 12.0002 18.8906C10.1287 18.8906 9.8941 18.8826 9.15912 18.8491C8.42578 18.8156 7.9248 18.6991 7.48663 18.5288C7.02667 18.3558 6.61029 18.0846 6.26605 17.7339C5.91559 17.3899 5.64441 16.9733 5.47137 16.5135C5.30109 16.0754 5.18445 15.5744 5.15112 14.8411C5.11725 14.1061 5.10938 13.8713 5.10938 12C5.10938 10.1287 5.11725 9.89392 5.15094 9.15912C5.18427 8.4256 5.30072 7.9248 5.47101 7.48645C5.64404 7.02667 5.91541 6.61011 6.26605 6.26605C6.61011 5.91541 7.02667 5.64423 7.48645 5.47119C7.9248 5.3009 8.4256 5.18445 9.15912 5.15094C9.89392 5.11743 10.1287 5.10938 12 5.10938C13.8713 5.10938 14.1061 5.11743 14.8409 5.15112C15.5744 5.18445 16.0752 5.3009 16.5135 5.47101C16.9733 5.64404 17.3899 5.91541 17.7341 6.26605C18.0846 6.61029 18.356 7.02667 18.5288 7.48645C18.6993 7.9248 18.8157 8.4256 18.8492 9.15912C18.8828 9.89392 18.8906 10.1287 18.8906 12C18.8906 13.8713 18.8828 14.1061 18.8491 14.8409Z" fill="#FBBE00"/>
              </svg>
            </span>
            <span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C18.6264 24 24 18.6264 24 12C24 5.3736 18.6264 0 12 0ZM17.4791 9.35632C17.4844 9.47443 17.4869 9.59308 17.4869 9.71228C17.4869 13.3519 14.7166 17.5488 9.65021 17.549H9.65039H9.65021C8.09473 17.549 6.64728 17.0931 5.42834 16.3118C5.64386 16.3372 5.86322 16.3499 6.08533 16.3499C7.37585 16.3499 8.56348 15.9097 9.50629 15.1708C8.30054 15.1485 7.28394 14.3522 6.93311 13.2578C7.10101 13.29 7.27368 13.3076 7.45074 13.3076C7.70215 13.3076 7.94568 13.2737 8.17712 13.2105C6.91681 12.9582 5.96741 11.8444 5.96741 10.5106C5.96741 10.4982 5.96741 10.487 5.96777 10.4755C6.33893 10.6818 6.76337 10.806 7.21527 10.8199C6.47571 10.3264 5.98956 9.48285 5.98956 8.52722C5.98956 8.02258 6.12598 7.5498 6.36255 7.14276C7.72083 8.80939 9.75073 9.90546 12.0399 10.0206C11.9927 9.81885 11.9683 9.60864 11.9683 9.39258C11.9683 7.87207 13.2019 6.63849 14.723 6.63849C15.5153 6.63849 16.2308 6.97339 16.7335 7.50879C17.361 7.38501 17.9502 7.15576 18.4825 6.84027C18.2765 7.48315 17.84 8.02258 17.2712 8.36371C17.8284 8.29706 18.3594 8.14929 18.8529 7.92993C18.4843 8.48236 18.0168 8.96759 17.4791 9.35632Z" fill="#FBBE00"/>
              </svg>
            </span>
            <span>@simplecashng</span>
          </li>
        </ul>
    </div>
    <div class="bg-primary py-2">
      <p class="text-white text-center">©2021 PaySimple Technologies Ltd. All rights reserved</p>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>