<template>
  <div class="mt-24">
    <h2 class="text-primary text-4xl font-semibold mb-16">What <span class="border-b-4  border-secondary">Our Agents</span> Say</h2>
    <div class="flex mb-16 mx-4 md:mx-0">
      <div class="w-2/12  bg-white flex justify-center items-center">
        <button class="rounded-full  h-16 w-16 shadow-xl flex justify-center items-center">
          <svg class="w-4" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.72904 13.5001L15.3645 24.1356L13.3021 26.198L0.604248 13.5001L13.3021 0.802246L15.3645 2.86464L4.72904 13.5001Z" fill="#1B2E50"/>
          </svg>
        </button>
      </div>
      <div class="w-10/12 flex overflow-x-scroll">
        <div class="rounded-md flex-shrink-0 w-96 bg-white p-6 border border-gray-200 mx-4">
          <div class="flex justify-between items-center">
            <h3 class="text-left font-semibold text-2xl"> Viktoria ventures</h3>
            <!-- <img class="rounded-full w-16" src="../assets/pexels.png" alt="avatar"> -->
          </div>

          <p class="my-8 text-left text-sm">
            I love the fact that I can easily reach out to your customer support to resolve any challenges
          </p>
        </div>

        <div class="rounded-md flex-shrink-0 w-96 bg-white p-6 border border-gray-200 mx-4">
          <div class="flex justify-between items-center">
            <h3 class="text-left font-semibold text-2xl">Hyos aluminum</h3>
            <!-- <img class="rounded-full w-16" src="../assets/pexels.png" alt="avatar"> -->
          </div>

          <p class="my-8 text-left text-sm">
            Your service is good and your customer support is always reliable and accessible.
          </p>
        </div>

        <div class="rounded-md flex-shrink-0 w-96 bg-white p-6 border border-gray-200 mx-4">
          <div class="flex justify-between items-center">
            <h3 class="text-left font-semibold text-2xl">Patie Innovations (Badore, Ajah)</h3>
            <!-- <img class="rounded-full w-16" src="../assets/pexels.png" alt="avatar"> -->
          </div>

          <p class="my-8 text-left text-sm">
             I like Simplecash quick response to issues .
          </p>
        </div>

        <div class="rounded-md flex-shrink-0 w-96 bg-white p-6 border border-gray-200 mx-4">
          <div class="flex justify-between items-center">
            <h3 class="text-left font-semibold text-2xl"> Matthew Mom's Store(Sango Ota)</h3>
            <!-- <img class="rounded-full w-16" src="../assets/pexels.png" alt="avatar"> -->
          </div>

          <p class="my-8 text-left text-sm">
            With SimpleCash I get my money immediately .
          </p>
        </div>


        
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>