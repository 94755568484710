<template>
  <div class="home">
    <Nav />
    <Banner />
    <Services />
    <Benefits />
    <Testimonies />
    <Faq />
    <AppAdvert />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Nav from '@/components/Nav.vue'
import Banner from '@/components/Banner.vue'
import Services from '@/components/Services.vue'
import Benefits from '@/components/Benefits.vue'
import Testimonies from '../components/Testimonies.vue'
import Faq from '../components/Faq.vue'
import AppAdvert from '../components/AppAdvert.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Nav,
    Banner,
    Services,
    Benefits,
    Testimonies,
    Faq,
    AppAdvert,
    Footer,
    

  }
}
</script>
