<template>
  <div class="mx-4 md:mx-10 mb-12">
    <div class="contain w-full md:h-72 p-8 md:p-0 flex flex-wrap">
      <div class="w-full md:w-6/12 flex justify-center">
        <img src="../assets/demo.png" alt="">
      </div>

      <div class="w-full mt-8 md:w-4/12 flex items-center">
        <div>
          <p class="text-white text-2xl text-left mb-6">
            Download and signup to be a 
            SimpleCash agent today! 
          </p>
          <button class="w-full">
            <img src="../assets/Googleplay.png" alt="">
          </button>
        </div>
       
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.contain {
  background: radial-gradient(28.93% 355.48% at 25.91% 45.41%, rgba(27, 46, 80, 0.83) 0%, #1B2E50 100%);
  border-radius: 15px;
}
</style>