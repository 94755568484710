<template>
  <div class="mt-24 px-4 md:px-24">
    <h2 class="text-primary text-4xl font-semibold mb-16">Simp<span class="border-b-4  border-secondary">leCash Bene</span>fits</h2>
    <div class="flex flex-wrap w-full items-center">
      <img class="md:mx-0 mx-auto" src="../assets/icon.png" alt="">
      <div class="w-full md:w-7/12 text-center md:text-left md:ml-8">
        <h4 class="font-semibold text-xl mb-2.5">Instant Settlement</h4>
        <p class="w-full text-center md:text-left md:w-8/12">
          You get your money settled into your wallet
          instantly on every transaction
        </p>
      </div>
    </div>

    <div class="flex flex-wrap-reverse w-full justify-end items-center">
      <div class="w-full md:w-5/12 text-center md:text-right md:mr-8">
        <h4 class="font-semibold text-xl mb-2.5">Agent Friendly Prices and Rates</h4>
        <p class="">
          We are first and foremost a customer service company -
          responding to customer complaints with promptness.
        </p>
      </div>
      <img class="md:mx-0 mx-auto" src="../assets/icon-2.png" alt="">
    </div>

    <div class="flex flex-wrap w-full items-center">
      <img class="md:mx-0 mx-auto" src="../assets/icon.png" alt="">
      <div class="w-full md:w-7/12 text-center md:text-left md:ml-8">
        <h4 class="font-semibold text-xl mb-2.5">SUPERIOR CUSTOMER SERVICES</h4>
        <p class="w-full md:w-8/12">
          We are first and foremost a customer service company - responding to customer complaints with promptness.
        </p>
      </div>
    </div>

    <div class="flex flex-wrap-reverse w-full justify-end items-center">
      <div class="w-full md:w-5/12 text-center md:text-left md:mr-8">
        <h4 class="font-semibold text-xl mb-2.5">RELIABLE SERVICE</h4>
        <p class="">
          Our services are reliable - 
          because we know your business depends on it.
        </p>
      </div>
      <img class="md:mx-0 mx-auto" src="../assets/icon-2.png" alt="">
    </div>

     <div class="flex flex-wrap w-full items-center">
      <img class="md:mx-0 mx-auto" src="../assets/icon.png" alt="">
      <div class="w-full md:w-7/12 text-center md:text-left md:ml-8">
        <h4 class="font-semibold text-xl mb-2.5">ACCESS TO CREDIT</h4>
        <p class="w-full md:w-8/12">
          Never run out funds with the simplecash agent financing scheme. Get the cash you need to keep your business afloat.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>