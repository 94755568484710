<template>
  <div class="banner w-full items-center justify-between flex ">
    <div class="w-full md:w-5/12 ml-4 md:ml-24 text-left">
      <h2 class="text-4xl md:text-6xl font-bold leading-relaxed md:leading-relaxed mb-6 text-primary"> <span class="border-b-4 mb-4 border-yellow-400">SimpleCash</span> <br> Agency Banking</h2>
      <p class="leading-relaxed">
        Becoming a simplecash agent empowers you to provide
        banking and payment services within your neighborhood.
      </p>
      <div class=" flex justify-center md:justify-start w-full my-6 ">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdvp6moJcSe8xdGZ_MLMlhT2-n8i_nk5f6UW1QDOmm--Tv96A/viewform"><button class="banner-btn bg-secondary px-6 py-2.5 my-4 text-xl text-primary">BECOME AN AGENT NOW </button></a>
        
      </div>
      
    </div>
    <div class="img-box hidden md:block">
      <img class="w-full" src="../assets/banner-img.png" alt="customer holding POS terminal">
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.banner {
  height: 90vh;
}
.banner-btn {
  box-shadow: 0px 4px 18px -2px rgba(251, 190, 0, 0.65);
  border-radius: 11px;
}
.img-box {
  width: 37%;
}
</style>