<template>
  <div id="faq" class="my-24 mx-4 md:mx-16">
    <h2 class="text-primary text-4xl font-semibold mb-16">F<span class="border-b-4  border-secondary">AQ</span>'s</h2>

      <accordion>
        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>What is SimpleCash?</h3>
            
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>

          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">SimpleCash is a solution that empowers store owners to provide agency banking services within their neighbourhood</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>How do I qualify for a loan?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">To qualify for a loan, you have to be SimpleCash agent for atleast 3months with a minimum monthly transaction of 2.5Million naira.</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>How do I payback my loan?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">You pay back your loan through a daily deduction from your purse.</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>What services does it offer?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">We offer all regular bank services such as withdrawal, funds transfer, deposit, etc.</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>Is there a registration fee to becoming a SimpleCash Agent?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">No - becoming a simplecash agent is free. You only pay for POS devices, if you need them</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>Can I become a SimpleCash agent without owning a POS</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">Yes - you can become a simplecash agent and perform most of the services using only your mobile phone. The only limitation is accepting card for transactions.</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>How much is the SimpleCash POS</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">There are various models of the simplecash POS. Ranging from Free to rental to outright purchase. The price is dependent of the current market price. Please send a mail to connect@yoursimplify.ng for more details</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>What do I need to register on simplecash?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">Your valid contact details and business information .</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>How secured is my money?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">Your money is secured with bank grade level security - only you have access to your money and we advise you to always safeguard your login credentials as well as your PIN. Change your credentials immediately if you suspect compromise and call our customer success team immediately if you suspect fraudulent access to your account.</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3> How do I access my funds?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">You can access your funds by transferring the money to other accounts for withdrawals.</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3> Is there a limit to my transactions on simplecash?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">There is no limit to the amount of transactions you can perform, however there is a limit of 200,000 on daily cash outs via transfer. This limit can  be increased with additional KYC requirements completed.</p>
          </template>
        </accordion-item>

        <accordion-item>
          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <h3>  Is my transaction PIN visible to simplecash?</h3>
            <svg class="w-4 h-4" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11616 7.50005L4.55811 2.94199L5.44199 2.05811L10.8839 7.50005L5.44199 12.942L4.55811 12.0581L9.11616 7.50005Z" fill="#1B2E50"/>
            </svg>
          </template>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <p class="p-2">No, your transaction PIN is not visible to us. Your unique PIN is created by you and should be known only by you. Simplecash would never ask for your PIN. Your PIN is encrypted and invisible to us at simplecash.</p>
          </template>
        </accordion-item>
      </accordion>
  </div>
</template>

<script>
import Accordion from "../components/accordion";
import AccordionItem from "../components/accordion-item";
  export default {
    components: {
      Accordion,
      AccordionItem
    }
  }
</script>

<style lang="scss" scoped>

</style>